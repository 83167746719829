<template>
  <div class="compaProfiCss">
    <Navbar :pageName="pageName" />
    <div class="contentMsgCss">
      <div class="leftMsgCss">
        <div v-for="(item, index) in afterSalesMsg.profiList" :key="index">
          <div class="titleCss">{{item.title}}</div>
          <img v-for="(url, i) in item.imgs" :key="i" :src="url" />
          <div class="msgCss" v-for="(msg, i) in item.msgList" :key="i">{{msg}}</div>
        </div>
      </div>
      <div class="rightMsgCss">
        <div class="borderLineCss" />
        <div class="labelContentCss">
          <div class="labelCss" v-for="(item,index) in afterSalesMsg.labelList" :key="index" @mouseenter="labelMouseenter(item)" @mouseleave="labelMouseleave(item)"  @click="clickLabel(item)">
            {{item.name}}
            <div class="iconContentCss">
              <svg-icon icon-class="right"  width="0.8rem" height="0.8rem" :color="item.iconColor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'
import { sbznycjkypt, shznwxglypt, wszdtznyyypt } from '@/assets/basicData.js'

export default {
  name: 'newsCent',
  components: {
    Navbar
  },
  data() {
    return {
      pageName: '',
      afterSalesMsg: {
        profiList: [],
        labelList: []
      },
      sbznycjkypt, // 设备智能远程监控云平台
      shznwxglypt, // 售后智能维修管理云平台
      wszdtznyyypt // 污水站动态智能运营云平台
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
    this.setAfterSalesMsg()
    window.addEventListener('setItem', () => {
      this.pageName = localStorage.getItem('pageName')
      this.setAfterSalesMsg()
    })
  },
  methods: {
    setAfterSalesMsg() {
      switch(this.pageName) {
        case '设备智能远程监控云平台' : this.afterSalesMsg = this.$lodash.cloneDeep(this.sbznycjkypt); break
        case '售后智能维修管理云平台' : this.afterSalesMsg = this.$lodash.cloneDeep(this.shznwxglypt); break
        case '污水站动态智能运营云平台' : this.afterSalesMsg = this.$lodash.cloneDeep(this.wszdtznyyypt); break
      }
    },
    labelMouseenter(row) {
      row.iconColor = '#E84C3D'
    },
    labelMouseleave(row) {
      row.iconColor = 'white'
    },
    clickLabel(row) {
      let toPath = ''
      switch(row.name) {
        case '设备智能远程监控云平台':
        case '售后智能维修管理云平台':
        case '污水站动态智能运营云平台': toPath = '/afterSales'; break
      }
      if (toPath !== '') {
        this.$router.push(`${toPath}?pageName=${row.name}`)
        this.$addStorageVal('pageName', row.name) // 在内存中设置跳转页面名
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
leftWidth = calc(41.8rem - 2px)

.compaProfiCss {
  padding-bottom 4rem
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  color white
  .contentMsgCss {
    padding 2rem 18rem 0 18rem
    width 63.8rem
    .leftMsgCss {
      float left
      width leftWidth
      border 1px solid #5c5959
      padding 1rem
      .titleCss {
        font-size 2rem
        height 4rem
        line-height 4rem
        border-bottom 1px solid white
      }
      .msgCss {
        margin-top 1rem
        text-indent 1.6rem
      }
      img {
        margin-top 1rem
        max-width leftWidth
      }
    }
    .rightMsgCss {
      float left
      padding 2rem 0 0 4rem
      width 14rem
      .borderLineCss {
        width 1.5rem
        border 2px solid white
        margin-bottom 0.5rem
      }
      .labelContentCss {
        margin-top 2rem
        .labelCss {
          padding 0 1rem
          color #666666
          height 2rem
          line-height 2rem
          transition-property color, background-color
          transition-duration 0.5s, 0.5s
          cursor pointer // 鼠标指针变手
          .iconContentCss {
            float right
          }
        }
        .labelCss:hover {
          color #E84C3D
          background-color white
        }
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
